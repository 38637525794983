.footer{
    background: #1E1E23;
    display: flex;
    flex-direction: column;
    color: white;
    margin: auto;
}
.footer__body{
    display: flex;
  align-items: center;

width: 100%;
text-align: left;
justify-content: center;
flex-wrap: wrap;
 
}
.footer__bodySect{
    width: 350px;
    text-align: left;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

   
}
.footer__bodySect p{
    text-align: left;
}
.footer__copyright{
    margin: auto;
}
@media screen and (max-width:505px) {
.footer__copyright {
       text-align: center;
    }
    .footer__bodySect{
        margin: auto;
        text-align: center;
        justify-content: center;
       width: 200px;
    }
}
@media screen and (max-width:320px) {
    .footer__copyright {
           width: 250px;
        }
     
}
.footer a{
    color: aliceblue;
}
.footer a:hover{
    border: none;
}
.footer a:focus {
    border: none;
}