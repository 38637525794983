@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100;1,200&family=Raleway:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap');
.hero__section{
    justify-content: center;
    display: flex;
    width: 100%;
    height: 400px;
    padding-top: 20px;
    margin-top: 20px;
}

.hero__sectionBody{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: auto 0px;
}
.hero__sectionBody .hero__sectionSlide img{
 
width: 400px;
height: 300px;
    object-fit: contain;
}
.slide__text{
    width: 200px;
    font-family: Montserrat;
    color: white;
}
.slide__text p{
    border-bottom: 2px solid #008136;
    padding-bottom: 10px;
    font-size: 30px;
    width: 100px;

}
.slide__text h3{
  
}

.hero__sectionBody .hero__sectionInfo .one img{
    object-fit: contain;
    width: 100px;
  
}
.hero__sectionInfoButtons  .one{
    margin: 0 10px;
}
.hero__sectionInfoButtons button{
    border-radius: 20px;
    padding: 5px 10px;
    border: none;
}
.hero__sectionBody .hero__sectionInfo .two img {
    object-fit: contain;
    width: 40px;

   
}
.hero__sectionInfo{
    align-items: center;
 
    width: 300px;
    flex:0.5
}
.hero__sectionInfo p{
    width: 300px;
    color: #D4CECE;
    font-family: Montserrat;
    letter-spacing: 0.2px;
    font-weight: normal;
}
.hero__sectionInfo h1{
    color: #FFFFFF;
    text-align: left;
    width: 300px;
    font-family: Raleway;
    letter-spacing: 0.2px;
    font-weight: normal;
}
.hero__sectionSlide{
    flex: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
}
.hero__sectionSlide .slideRadio {
    position: absolute;
    bottom: -40px;
    left: 55%;
    color: #719983;
}

.hero__sectionSlide .slideRadio #radio{
    border:none;
    z-index: 100;
    background: #B1FFD2;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
}
.hero__sectionSlide .slideRadio #radio:focus{
background: #008136;
}
.slideShow{
    display: flex;
    flex-wrap: wrap;
}
.slideRadio button{
    background: none;
    border: none;
}
.hero__sectionInfo .hero__sectionInfoButtons{
    display: flex;
    margin: 30px 10px;
}
.hero__section .container__dots {
    position: absolute;
    bottom: -35px;
    left: 75%;
    transform: translateX(-180%);
    display: flex;
   
}

.dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 5px;
    background: #719983;
}

.dot.active {
    background: #008136;
}
@media  screen and (max-width:940px) {
    .hero__section{
        padding-top: 40px;
       
    }
        .hero__sectionInfo p {
            width: 250px;
           
        }
    
        .hero__sectionInfo h1 {
           
            width: 250px;
            
        }
}
@media screen and (max-width:896px) {
    .hero__section {
        
        height: 600px;

    }

   .slideShow{
       justify-content: center;
   }
   .hero__section{
       margin-left: -40px;
   }
}
@media screen and (max-width:585px) {
    .hero__section {
        height: 1000px;

    }

    .slideShow {
        justify-content: center;
    }

    .hero__section {
        margin-left: -40px;
    }
        .hero__sectionInfo {
          margin-top: 20px;
        }
}
@media screen and (max-width:425px) {
    .hero__sectionBody .hero__sectionSlide img {
            width: 300px;
            height: 300px;
            object-fit: contain;
        }
                .download__header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0;
                    flex-wrap: wrap;
                }
                .download__header img{
                    
                }
}
@media  screen and (max-width:320px) {
    .hero__sectionBody .hero__sectionSlide img {
          width: 230px;
        }
    .hero__sectionInfo {
         margin-left:100px ;
  
         }
          .hero__sectionBody .hero__sectionSlide {
              margin-left: 60px;
          }
}
.download__app{
    display: flex;
    flex-direction: column;
  
    margin: auto;
    height: 95vh;

}
.download__appBody{
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
}
.download__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 50px;
    flex-wrap: wrap;
}
.user__name{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.user__name strong,button{
    margin: 0 10px;
    text-transform: capitalize;
    cursor: pointer;
}
.user__name button{
    border-radius: 20px;
        padding: 5px 20px;
        border: none;
        background-color: #008136;
        font-family: raleway;
}
.user__name strong{
    color: white;
    font-family: raleway;
    cursor: pointer;
}
.download__appBody {
    color: white;
    font-family: raleway;
}
.download__footer{
    width: 100%;
}