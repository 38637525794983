@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100;1,200&family=Raleway:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap');

.verify__page{
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}
.verify__page h1{
    color: white;
    font-family: Raleway;
    text-align: center;
    margin-top: 150px;
}
.verify__pageBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
}
.verify__page input{
    background-color: transparent;
    width: 300px;
    height: 30px;
    border: 1px solid #D2FFE5;
    margin: 20px 0;
    border-radius: 20px;
    padding: 10px;
    font-size: 20px;
    color: white;
}
.verify__pageBody button{
    border: none;
    width: 100px;
    height: 30px;
    border-radius: 20px;
    background-color: #008136;
    color: #D2FFE5;
    font-family: Raleway;
}
.verify__pageBody button:hover{
    background-color: #D2FFE5;
    color: black;
}