@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100;1,200&family=Raleway:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap');
.about{
    display: flex;
    align-items: center;
    height:404px;
    justify-content: space-between;
    padding: 0px 5px;
    padding-top: 50px;
    width: 100%;
    flex-wrap: wrap;
    margin: 20px auto;
    position: relative;
 
    
}
.about__left{
    display: flex;
    flex-direction: column;
    width: 350px;
    align-items: center;
    margin: auto;
}
.about__left h1{
    font-family: Raleway;
    color: white;
    font-size: 50px;
}
.about__left p{
    letter-spacing: 0.2px;
        color: #D4CECE;
        text-align: left;
            font: normal normal normal 20px/32px Montserrat;
}
.about__left button{
    border: none;
    border-radius: 49px;
    background: #008136 0% 0% no-repeat padding-box;
    width: 343px;
    height: 80px;
    text-align: center;
        font: normal normal bold 30px/34px Arial;
        letter-spacing: 0px;
        color: #FFFFFF;
}
.about__right{
  width: 350px;
    align-items: center;
    display: flex;
    margin: auto;
    position: relative;

   
}
.about__right .image__1{
    min-width: 100px;
    width: 10%;
    height: 100px;
    object-fit: contain;
    border-radius: 50%;
    border: 5px solid white;
    position: absolute;
    top: -180px;
    left:-60px;
}
.about__right .image__2 {
    min-width: 100px;
    height: 100px;
    width: 10%;
    object-fit: contain;
    border-radius: 50%;
    border: 5px solid white;
    position: absolute;
    top: -20px;
    left: -110px;
}
.about__right .image__3 {
    min-width: 100px;
    height: 100px;
    width: 10%;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid white;
    position: absolute;
    top: 110px;
    left: -20px;
}
.about__right .image__4 {
    min-width: 250px;
    width: 60%;
    height: 250px;
    object-fit: cover;
    border-radius: 50%;
    position: absolute;
    top: -130px;
    left: 20px;
    border: 5px solid white;
   
}
.after__about{
    position: relative;
    height: 450px;
    margin-top: 220px;
    mask-image: rgba(0,0,0,0.3);
    align-items: center;
    display: flex;
}
.after__about::after {
    content: "";
    background-image: url('../images/dla_above.jpg');
    background-size: cover;
    position: absolute;
      top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    opacity: 0.3;
    z-index: -1;
    transform: rotate(-5deg) scale(1.1);
  
}
.after__about h1{
    font-size: 140px;
    color: #FFFFFF;
    opacity: 0.4;
    align-items: center;
    padding-left: 100px;
}
@media screen and (max-width:896px) {
.about__right {
        margin-right: 20px;


    }
}
@media screen and (max-width:896px) {
    .about__right {
        margin-left: 150px;


    }
}
@media screen and (max-width:862px) {
    .about__right {
        margin-left: 150px;
        margin-top: 150px;
    }
           .after__about {
               margin-top: 300px;
               height: 300px;
           }
    .about{
        height:800px;
        margin-top: -50px;
        margin-left: 30px;
    }
}
@media screen and (max-width:706px) {
    .about {
        height: 800px;
        margin-top: -60px;
        margin-left: 30px;
    }
        .about__right .image__1 {
           
            position: absolute;
            top: -140px;
            left: -10px;
        }
    
        .about__right .image__2 {
           
            position: absolute;
            top: 10px;
            left: -60px;
        }
    
        .about__right .image__3 {
         
            position: absolute;
            top: 150px;
            left: 30px;
        }
    
        .about__right .image__4 {
         
            position: absolute;
            top: -90px;
            left: 70px;
          
    
        }
}
@media screen and (max-width:585px) {
    .about__right .image__1 {
    
            position: absolute;
            top: -140px;
            left: -125px;
        }
    
        .about__right .image__2 {
    
            position: absolute;
            top: 10px;
            left: -170px;
        }
    
        .about__right .image__3 {
    
            position: absolute;
            top: 150px;
            left: -75px;
        }
    
        .about__right .image__4 {
    
            position: absolute;
            top: -90px;
            left: -60px;
    
    
        }
                
}
@media screen and (max-width:628px){
.after__about h1 {
        font-size: 85px;
        text-align: center;
        margin: auto;
        padding-left: 40px;

    }
}
@media screen and (max-width:425px) {
    .about__right .image__1 {
    
            position: absolute;
            top: -120px;
            left: -90px;
        }
    
        .about__right .image__2 {
    
            position: absolute;
            top: 10px;
            left: -140px;
        }
    
        .about__right .image__3 {
    
            position: absolute;
            top: 140px;
            left: -65px;
        }
    
        .about__right .image__4 {
            min-width: 200px;
            height: 200px;
            position: absolute;
            top: -50px;
            left: -20px;
    
    
        }
                .after__about h1 {
                    font-size: 65px;
                    text-align: center;
                    margin: auto;
                    padding-left: 40px;
        
                }
}

@media screen and (max-width:395px) {
    .about__left {
            width: 270px;
            margin-top: -20px;
        }
        .about__left h1 {
            font-family: Raleway;
            color: white;
            font-size: 50px;
        }
        .about__left button {
            width: 220px;
            height: 70px;
        }
                
}

@media screen and (max-width:320px) {
    .about__right .image__4 {
            min-width: 130px;
            height: 160px;
            position: absolute;
            top: -40px;
            left: -50px;
    
    
        }
                .about__right .image__1 {
                    min-width: 80px;
                    height: 80px;
                    position: absolute;
                    top: -110px;
                    left: -90px;
                }
        
                .about__right .image__2 {
                    min-width: 80px;
                    height: 80px;
                    position: absolute;
                    top: 10px;
                    left: -140px;
                }
        
                .about__right .image__3 {
                    min-width: 80px;
                    height: 80px;
                    position: absolute;
                    top: 125px;
                    left: -65px;
                }
                .about__left{
                width: 200px;
                margin-top: 10px;
                }
                .about__left h1{
                    font-size: 30px;
                    text-align: center;
                }
                .about__left p{
                    font-size: 15px;
                }
   
}
@media screen and (max-width:360px) {
   .about__left{
       
   }
}