@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100;1,200&family=Raleway:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap');
.comfirmation__page{
    display: flex;
    flex-direction: column;
    position: relative;
 
}
.body{
    height: 95vh;
}
.footer{
    width: 100%;
}
.admin{
    display: flex;
    align-items: center;
    
}
.comfirmation__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 50px;
}
.comfirmation__header h3{
    color: white;
    font-family: Raleway;
    cursor: pointer;
    background-color: #008136;
    border-radius: 20px;
    padding: 5px 10px;
    margin: 0 5px;
}
.comfirmation__header h3:hover{
background-color: white;
color: black;
border: 2px solid #008136 ;

}
.comfirmation__header h2{
    color: #008136;
    font-family: Raleway;
    cursor: pointer;
}
.comfirmation__body{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    background-color: rgba(44,44,44,0.66);
    margin: auto 100px;
    margin-top: 150px;
    padding: 80px;
    border-radius: 80px;
}
.comfirmation__body img{
    width: 100px;
}
.comfirmation__body h4{
    color: white;
    font-family: Raleway;
    font-size: 25px;
    text-align: center;
}
.comfirmation__page::after {
    content: "";
    background-image: url('../images/Path156.svg');
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.02;
    z-index: -1;
}
@media screen and (max-width:860px) {
    .body {
        height: 100vh;
    }

    .footer {
        position: relative;

        width: 100%;
    }
}