@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100;1,200&family=Raleway:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap');
.admin__headerContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 60px;

    
}
@media screen and (max-width:425px) {
    .admin__headerContainer{
        height:280px;
    }
  
  .admin__content .create__user { 
 
     }
        
     .admin__content .create__user button {            
     padding: 5px;
    font-family: Raleway;
    font-weight: bold;
position:absolute;
top:145px;
right:-45px;
height: 30px;
    width: 100px;
    font-size: 10px;
    align-items: center;
    margin: auto;
    }
    
}
.admin__headerSearch{
    display: flex;
    align-items: center;
    border: 1px solid gray;
    border-radius: 20px;
    padding :5px 20px;
    width: 300px;
    height: 20px;
    justify-content: space-between;

}
@media screen and (max-width:768px) {
    .admin__headerSearch{
        width:200px;
        padding: 5px 10px;
    }
}
.admin__headerSearch input{
    background: none;
    border: none;
    height: 20px;
    margin-left: 5px;
    flex: 1;
    color: white;
    font-family: Raleway;
}
.admin__headerSearch>input:focus {
    outline-width: 0;
}
.admin__headerLogo h1{
    color: #008136;
    font-size: 50px;
    cursor: pointer;
}
.admin__headerProfile{

}
.admin__headerProfile span{
     display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Raleway;
    align-items:flex-end;

}
.admin__headerProfile span strong{
    background-color: #008136;
    padding: 5px 10px;
    border-radius: 5px 10px;
    color: white;
    width:80px;
    text-align: center;
    cursor: pointer;
}
.loading{
    transform: scale(0.4);
    margin-top: -170px;
}
.admin__headerProfile span small{
    font-size: 30px;
    text-transform: capitalize;
    color:white;
}
.admin__page{
    background-color: #313132;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
 
   
    
}
.admin__loading{
    background-color: #2D2D2E;
    width: 100%;
    height: 50%;
    align-items: flex-start;
    margin-top: 190px;
   
}
.admin__loading2 {
    background-color: #2D2D2E;
    width: 100%;
    height: 50%;
    align-items: flex-start;

}
.admin__body{
    display: flex;
    justify-content: space-between;
    height: 100%;
    

}
.admin__contentBody{
display: flex;
flex-direction: column;
height: 95vh;
background-color: #2D2D2E;
}
.admin__sidebar{
height: 400px;
}
.admin__footer{

    width: 100%;
}
.admin__content{
    flex: 2;
    margin-left: -0px;
    background-color: #2D2D2E;
    padding: 5px 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
   align-items: flex-start;
    width: 300px;
}
.admin__content .create__user{
    position: absolute;
    right: 60px;
   top: 150px;
}
.admin__content .create__user button{
    background-color: #008136;
    border: none;
    padding: 5px 10px;
    border-radius: 10px 20px;
    font-family: Raleway;
    font-weight: bold;
}
.admin__content h3{
    color: white;
    font-family: Raleway;
}
.admin__contentRow{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}
.admin__contentColumn{
    display: flex;
    flex-direction: column;
    align-items: center;
   
}
.admin__sidebarContainer{
    position: relative;

   
        
}
.admin__sidebarContent{
    width: 160px;
    display: flex;
    flex-direction: column;
    color: white;
    font-family: Raleway;
 height: 600px;
        padding: 10px 50px;
        border-top-left-radius: 40px;
        background-color: #2D2D2E;
        box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
.hidden__sidebar{
    width: 50px;
    height: 600px;
        background-color: #2D2D2E;
        box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
.admin__sidebarContent h3{
    font-size: 20px;
    font-weight: 800;
    text-align: center;
}
.admin__sidebarContainer span{
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius:20px ;
    width: 140px;
    cursor: pointer;
    color: white;
}
span {
height: 30px;
}

button{
    color: white;
    font-size: 15px;
    margin: 5px 0;
}
.all__bikesSection button:hover{
 background-color: #008136;
text-align: center;
 color: white;
}
.all__bikesSection button:focus {
    background-color: #008136;
    color: white;
}
.all__bikesSection button{
 
    height: 30px;
    text-align: center;
    display: flex;
    padding: 15px  10px;
  
}
.all__bikesSection{
    margin-left: 30px;
}

 .admin__sidebarContent  .side__icon{
position: absolute;
right:-19px;
top:300px;
background-color: #4E4E50;
border: 1px solid white;
border-radius: 50%;
 }
.hidden__sidebar .side__icon {
      position: absolute;
      right: -19px;
      top: 300px;
      background-color: #4E4E50;
      border: 1px solid white;
      border-radius: 50%;
  }
  .button{
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-radius: 20px;
        padding: 5px 5px;
  }
  span .button:hover{
background-color: #008136;
color: white;
  }
        span .button:focus {
            background-color: #008136;
            color: white;
        }

.admin__bikeDetail{
    display: flex;
    flex-direction: column;
    background-color: #373739;
    border-radius: 20px;
    width: 20%;
    height: 320px;
    min-width: 290px;
    margin: 10px 0;
    margin-right: 10px;
    padding: 5px 10px;
    font-family: Raleway;
    position: relative;
    transition: 0.4s;
}
.admin__bikeDetail:hover{
    
    padding: 0;

}
.admin__bikeDetail:hover .admin__bikeDetailBody{
    background-color: #008136;
    transform: scaleY(1.1);
    
}
.admin__bikeDetail:hover .admin__bikeDetailBody button {
    background-color: #2D2D2E;
    color: white;
}
.admin__bikeDetailBody{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 5px auto;
    justify-content: center;
    border-radius: 20px;
    position: absolute;
    bottom: 0;
}
.admin__bikeDetailBody span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  

}
.admin__bikeDetailBody span small{
margin-left: -65px;
color: #D4CECE;
width: 150px;
font-size: 12px;

}
.admin__bikeDetailBody span strong{
    color: white;
    width: 80px;
}
.bike__icon{
    background-color: white;
    padding:5px;
    border-radius: 50%;
    text-align: center;
    margin: 5px auto;
}
.admin__bikeTitle h3{
    color: white;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 10px;
    margin-top:-2px;
}
.admin__bikeDetail button span{
    align-items: center;
    justify-content: center;
   

}
.admin__bikeDetailBody button{
    margin: 5px auto;
    background-color: #008136;
    border: none;
    padding: 5px 10px;
    width: 120px;
    border-radius: 10px 20px;
}

.track__bike{
    margin: 5px auto;
}
.admin__sidebar .all__bikes{
    height: 230px;
}
.input{
    display: flex;
    align-items: center;
    border: 1px solid gray;
    border-radius: 20px;
    padding: 2px 5px;
    width: 25px;
    height: 20px;
    justify-content: space-between;
    background-color: white;
}
.input input{
   width: 25px;
    height: 20px;
    border: none;
    font-size: 15px;
    border-radius: 20px;
  
}
.input>input:focus {
    outline-width: 0;
}
.input button{
    background: transparent;
    border: none !important;
    font-size: 0;
}
.content__header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
.content__header small{
    color: #008136;
    font-family: Raleway;
    margin-right: 5px;
    font-size: 15px;
}
.syn__input{
    display: flex;
    align-items: center;
    margin-top: -15px;
    margin-bottom: 10px;
}
/** pagination style*/
.paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
}

.paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #008136;
    color: #008136;
    cursor: pointer;
}

.paginationBttns a:hover {
    color: white;
    background-color: #008136;
}

.paginationActive a {
    color: white;
    background-color: #008136;
}

.paginationDisabled a {
    color: grey;
    background:transparent;
    font-size: 0;
    border: none;
}
.edit__button {
    position: absolute;
    width: 70px;
        background-color: #008136;
        border: none;
        padding: 5px 10px;
   right: 5px;
   top: 0px;
        border-radius: 10px 20px;
}
.page__number nav ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.page__number nav p{
    color: #008136;
    border: 1px solid #008136;
    margin: 5px;
    padding: 5px 7px ;
    cursor: pointer;
}
.page__number{
margin: 0 auto;
}
.page__number .active{
    background-color: #008136;
}
.unauthorized{
 
    height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.unauthorized .body{
display: flex;
    background-color: #4E4E50;
    text-align: center;
    border-radius: 20px;
    padding: 10px 20px;
    width: 350px;
    flex-direction: column;
    align-items: center;
    height: 150px;
    color: white;
    font-family: raleway;
}
.unauthorized .body button{
    background-color: #008136;
    border: none;
    border-radius: 20px;
    padding: 5px 10px;
}