@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100;1,200&family=Raleway:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap');
.login__page{
position: relative;
width: 100%;

}
.login__page::after{
    content: "";
        background-image: url('../images/Path156.svg');
        background-size: cover;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.02;
        z-index: -1;
}
.login__pageHeader h1{
    color:#008136;
    padding: 10px;
    margin: 0;
    font-size: 40px;
    font-family: Montserrat;
    cursor: pointer;

}
.login__pageBody{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: auto;
    width: 85%;
    min-width: 500px;
    margin-top: -50px;

 color: #008136;
   
}
.login__pageBody .left__side{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: scale(0.9);

}
.left__sidePics{
    width: 200px;
    transform: scale(0.6);
    margin-bottom: -90px;
    margin-left: -80px;
}
.right__side{
    align-items: center;
    background-color: rgba(44, 44, 49, 0.66);
    padding: 20px;
    height: 450px;
    width: 450px;
    border-radius: 30px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
   
}
.right__side button{
    border: none;
    background-color: #008136;
    height: 50px;
    width: 180px;
    border-radius: 40px;
    color: white;
    font-weight: bold;
    font-family: Montserrat;
    font-size: 20px;
    margin: 20px 0;
}
.right__side h1{
    font-family: Montserrat;
    color: white;
    font-size: 30px;
    font-weight: normal;
}
.login__input input{
    background:#161618 ;
    border-radius: 21px;
    border: 1px solid #D2FFE5;
    height: 50px;
    width: 350px;
    padding:10px 25px;
    margin: 20px 0;
    color: white;

}

@media screen and (max-width:928px) {
    .right__side { 
            height: 450px;
            width: 300px;
        }
                .login__input input {
                
                    height: 50px;
                    width: 200px;
                    padding: 10px 25px;
                    margin-bottom: 20px;
        
                }
}
@media screen and (max-width:862px) {
    .left__sidePics {
            width: 400px;
            transform: scale(0.6);
            margin-bottom: -380px;
            margin-top: -80px;
        }
        .login__pageBody{
            margin-top: -170px;
        }
}
@media screen and (max-width:795px) {
    .login__pageBody{
        flex-direction: column-reverse;
        margin-top: 10px;
    }
  .left__side{
      margin-top: -60px ;
  }

}

@media screen and (max-width:425px) {
    .login__pageBody {
           min-width: 200px;
        }
    
      
}
@media screen and (max-width:360px) {
    .login__pageBody {
        width: 100%;
        min-width: 80px;
    }
    .login__page .hero__sectionBody {
        min-width: 100px;
        width: 80%;
     
    }
}
@media screen and (max-width:330px) {
    .login__pageBody {
        width: 100%;
        min-width: 80px;
        padding: 0px;
    }

    .login__page .hero__sectionBody {
        min-width: 50px;
        width: 50%;

    }
}
@media screen and (min-width:1441px) {
    .login__pageBody {
  height: 95vh;
    }

  
}
.reset__password{
    color:#008136;
    margin-top: -10px;
}
.reset__link{
    color: #008136;
    cursor: pointer;
}

.reset__link:hover{
    border-bottom: 1px solid #008136;
}