.create_profilePage {
    display: flex;
    flex-direction: column;
    margin: 100px auto;
    align-items: center;
    width: 600px;
    height: 100vh;
    max-height: 1000px;
    border: 2px solid black;
    padding: 10px;
    color:red;

}

.create_profilePage .login_input {
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 5px
}

.create_profilePage input {
    margin-left: 30px;
    width: 200px;
}

.create_profilePage label {
    width: 100px;
    align-items: center;
    color: black;
}