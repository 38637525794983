@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100;1,200&family=Raleway:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap');
.edit__page{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
    height: 80vh;
}
.edit__upButtons{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.download__header h1{
    color:#008136;
    cursor:pointer;
    font-family: raleway;
    font-weight: bold;
}

.edit__upButtons .edit{
    background-color: #008136;
        border: none;
        padding: 5px 10px;
        border-radius: 10px;
        font-family: Raleway;
        font-weight: bold;
        margin: 5px;
}
.edit__page form{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.edit__page form input{
    display: flex;
        align-items: center;
        border: 1px solid gray;
        border-radius: 20px;
        padding: 5px 20px;
        width: 300px;
        height: 40px;
        background: none;
        margin: 10px 0;
        color: white;
        font-family: Raleway;
        font-size: 20px;
}
.edit__page form>input:focus {
    outline-width: 0;
}
.edit__page form button{
        background-color: #008136;
            border: none;
            padding: 5px 20px;
            border-radius: 10px;
            font-family: Raleway;
            font-weight: bold;
            margin: 5px;
            width: 330px;
            height: 40px;
}
.edit__page .delete{
    background-color: red;
    border: none;
        padding: 5px 10px;
        border-radius: 10px;
        font-family: Raleway;
        font-weight: bold;
        margin: 5px;
}
.edit__page strong{
    color:#008136;
    text-transform: capitalize;
}
.delete__popup{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 460px;
    padding: 10px;
    background-color: #313132;
    box-shadow: 1px 1px 5px 0px rgba(247, 247, 247, 0.75);
        -webkit-box-shadow: 1px 1px 5px 0px rgba(247, 247, 247, 0.75);
        -moz-box-shadow: 1px 1px 5px 0px rgba(247, 247, 247, 0.75);
}
.delete__popup h1{
    color: white;
    font-family: raleway;
    text-align: center;

}
.delete__popup .popup__buttons{
    margin: 10px auto;
    display: flex;
}
.delete__popup h5{
    color: #008136;
    text-align: center;
    font-family: raleway;
    font-size: 17px;
}
.popup__buttons .yes{
    background-color: red;
        border: none;
        padding: 5px 10px;
        border-radius: 10px;
        font-family: Raleway;
        font-weight: bold;
        width: 60px;
        margin: 5px;
}
.popup__buttons .no {
    background-color: #008136;
    border: none;
    padding: 5px 10px;
    border-radius: 10px;
    font-family: Raleway;
    font-weight: bold;
    margin: 5px;
    width: 60px;
}
.delete__body{
    display: flex;
    flex-direction: column;
    width: 300px;
    border: 1px solid #008136;
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-family: raleway;
}
.user__name> .admin__home:hover {
  border-bottom: 2px solid #008136;
 padding-bottom: 5px;
}
