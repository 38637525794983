.change{
    position: absolute;
    top: -5px;
    right: 0px;
    z-index: 1000;
    
}
.change button{
    border: none;
    color: white;
    background-color: #008136;
    padding: 10px;
    border-bottom-left-radius: 10px;
  
}

.reset__password{
    display: flex;
    flex-direction: column;
    margin:auto;
    align-items:center;
    justify-content: center;
    font-family: raleway;
}
.reset__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:auto;
    height: 95vh;

}
.reset__content h3{
    text-align: center;
    font-size: 30px;
    color:#008136;


}
.reset__content form{
    display:flex;
    flex-direction: column;
    width:300px;
    background-color: #313132;
    padding:20px 30px;
    border-radius: 20px;

}
.reset__content form input{
    height: 30px;
    background: none;
    border-radius: 10px;
    color:white;
    margin: 5px 0;
    padding: 5px;
}
.reset__content button{
    border: none;
    border-radius: 10px;
    background-color: #008136;
    height: 30px;
}
.reset__content p{
    text-align: center;
}
.reset__content p{
    text-align: center;
    margin:5px auto;
    padding-bottom: 5px;
    cursor: pointer;
}
.reset__content p:hover{
    border-bottom: 1px solid #008136;
}
