@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100;1,200&family=Raleway:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap');
.create__accountBody{
    display: flex;
    background-color: rgba(44, 44, 49, 0.76);
    width: 70%;
    flex-wrap: wrap;
    min-width: 300px;
    max-width: 800px;
    margin: auto;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}
.create__account{
    margin: auto;
    align-items: center;
}
.create__accountBody strong{
    color: #008136;
    text-align: center;
}
.create__account h2{
    color: white;
    font-family: Raleway;
}
.create__account::before{
    content: "";
    background-image: url('../images/Path156.svg');
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.02;
    z-index: -1;
}
.right__sideInputs,.left__sideInputs{
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    padding: 10px;
}
.left__sideInputs{
    margin-top: -36px;
    width: 300px;
}
.right__sideInputs{
    margin-bottom: 0px;
    width: 300px;
}
.create__accountBody input{
    background-color: transparent;
    border: 1px solid #D2FFE5;
    margin: 5px 0;
    border-radius: 10px;
    width: 280px;
    height: 32px;
    padding: 0px 10px;
    color: white;
}
.number__input input{
    width: 230px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
}
.number__input{
    display: flex;
    justify-content: space-between;
}
.code{
    border: 1px solid #D2FFE5;
    height: 30px;
    align-items: center;
    display: flex;
    margin: auto;
    width: 43px;
    padding: 1px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.create__account button{
   margin-top: 10px;
        border-radius: 5px;
        width: 300px;
        height: 30px;
        border: none;
        color: white;
        background-color:#008136 ;
        font-family: Raleway;
        padding: 10px;
        align-items: center;
    
        display: flex;
        justify-content: center;
}
button:hover{
    background-color: #D2FFE5;
    color: black;
    font-weight: bold;
}
.create__adminBody{
    height: 95vh;
}
.create__footer{
   
    width: 100%;
}
.create__accountBody select{
background-color: transparent;
    border: 1px solid #D2FFE5;
    margin: 5px 0;
    border-radius: 10px;
    width: 300px;
    height: 32px;
    padding: 0px 10px;
    color: white;
}
.create__accountBody select option{
    color:black;
}
@media screen and (max-width:1024px) {
.create__adminBody {
        height: 100vh;
    }
        .create__footer {
            position: relative;

            width: 100%;
        }
}
.create__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 50px;
    flex-wrap: wrap;
}
.create__header h3{
    cursor: pointer;
    color: white;
        font-family: Raleway;
        background-color: #008136;
        border-radius: 20px;
        padding: 5px 10px;
        margin: 0 5px;
}
.create__header h3:hover{
    background-color: white;
        color: black;
        border: 2px solid #008136;
}
