@import url('https://fonts.googleapis.com/css2?family=Enriqueta:wght@700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,900;1,100;1,200;1,300;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap');

.header__bg{
    background-color:#2D2D2E;
    border-bottom: 1px solid #D4CECE;
}

.header {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
  }

.header .navbar {
    display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1240px;
        margin: auto;
        height: 100%;
        padding: 0 1rem;
}

.header .nav__menu a {
    text-align: left;
        font: normal normal normal 24px/39px Montserrat;
        letter-spacing: 0.48px;
        color: #D4CECE;
}
.header .nav__menu button{
    border: none;
    background-color: #008136;
    border-radius: 10px;

    height: 50px;
    color: #D4CECE;
    font-family: Montserrat;
    font-size: 15px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;

}
.header .nav__menu button:hover{
    background-color: #D4CECE;
    color: black;
}

.header .navbar h1 {
    width: 200px;
    height: auto;
    color: #008136;
    font-size: 50px;
 
}

.header .nav__menu {
    display: flex;
    align-items: center;
}

.header .nav__item {
    padding: 1rem;
    font-weight: 500;
}

.header .nav__item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px #008136 solid;
       
}
.header .nav__item a:focus{
    padding-bottom: 12px;
    border-bottom: 3px #008136 solid;

}
.header .nav__item a:active {
    padding-bottom: 12px;
    border-bottom: 3px #008136 solid;

}

.navbar__mobileView {
    display: none;
}


@media screen and (max-width:1030px) {
    .header {
        max-width: 100%;
        background-color: #1E1E23;
    }

    .header .navbar {
        max-width: 100%;
    }

    .navbar__mobileView {
        display: block;
    }

    .nav__menu {
        position: fixed;
        left: -110%;
        top: 90px;
        flex-direction: column;
        background-color:#1E1E23;
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav__menu.active {
        left: 0;
    }

    .nav__item {
        margin: 1.5rem 0;
    }

    .header .navbar  {
        width: 150px;
    }
}