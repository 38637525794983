body {
  margin: 0;
  padding: 0;
  background-color: #2D2D2E;
  overflow-x: hidden;
  scroll-behavior: smooth;
  box-sizing: border-box;
  width: 100%;
  position: relative;


}
ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}
button{
  cursor: pointer;
}
.logo{
  width:300px;
  cursor: pointer;
  margin-left:180px;
  margin-top: 20px;
 
}
.logo__h{
cursor: pointer;
margin-top: 10px;
width: 250px;
}
.logo__a{
    width: 300px;
      cursor: pointer;
      margin-left: 0px;
      margin-top: 20px;
}

@media screen and (max-width:1030px) {
  .logo__h {
    width:100%;
margin-right: 20px;
    display:flex;
    justify-content: flex-start;
    }
    .logo__a{
      width:200px;
    }
}
@media screen and (max-width:697px) {
  .logo {
     
      margin-left: 50px;
   
  
    }
}
@media screen and (max-width:457px) {
  .logo {
width: 200px;
    margin-left: 10px;


  }
}