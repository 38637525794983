.home_page{
  overflow-x: hidden;
  width: 100%;


}

.home__pageHeader{
position: relative;
}
.home__pageHeader::after{
    content: "";
        background-image: url('../images/Path156.svg');
        background-size: cover;
        position: absolute;
        top: -19px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.02;
        z-index: -1;
}
