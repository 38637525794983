.detail__header{
    width: 100%;
    margin: 0;
    background-color: #313132;
}
.detail__body{
    position: relative;
    display: flex;
    
}
.detail__sidebar{
    position: relative;
}
.detail__content{
    display: flex;
    flex-direction: column;
    flex: 2;
    margin-top: 30px;

}
.detail__contentBody{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.bike__info{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 20px;
    color: white;
    font-family: Raleway;
    background-color: #2F2F30;
    border-radius: 24px;
    padding:10px 20px ;
}
.more__info{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.more__info p{
    font-size: 10px;
    margin: 0 10px;
}
.bike__infoBody{
    margin-top: -20px;
}
.bike__info h5{
    text-align: center;
    font-weight: normal;
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 5px;
}
.detail__map{
    margin-right: 20px;
    background-color: #343436;
    border: 1px solid #707070;
    height: 100vh;
    flex: 1;
    border-radius: 50px;
    
}
.detail__content h3{
    color: white;
    font-family: raleway;
    margin-left:20px ;
}
@media screen and (max-width:425px) {


}