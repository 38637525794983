.howto{
    display: flex;
    flex-direction: column;
    padding: 30px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    color: white;
    margin: 50px 0;
}
.howto .howto__body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.howto>h1{
    margin: auto;
    color: white;
    font-weight: normal;
    font-size: 60px;
    margin: 50px auto;
    text-align: center;
}
.howto__stepOne{
    width: 20%;
    min-width:350px;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #373739;
    padding: 10px;
    text-align: center;
    margin: 10px;
    height: 300px;
  

}
.howto__stepOne p{
    color: #D4CECE;
}
p{
    font-style: normal;
    font-weight: normal;
}
.howto__stepTwo{
    width: 20%;
    min-width: 350px;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #008136;
    padding: 10px;
    text-align: center;
    margin: 0 10px;
    box-shadow: 10px 10px 5px 0px rgba(1, 1, 1, 0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(1, 1, 1, 0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(1, 1, 1, 0.75);
}
.howto__stepOne h1{
    background: #262627 0% 0% no-repeat padding-box;
    border: 1px solid #95989A;
    padding: 15px;
    border-radius: 50%;
    color: white;
}
.howto__stepTwo h1{
    background: rgba(1, 1, 1, 0.6);
        border: 1px solid #95989A;
        padding: 15px;
        border-radius: 50%;
        color: white;
      
}
.after__howto img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    transform: rotate(-3deg) scale(1.1);
    margin: 50px 0;
}

@media screen and (max-width:395px) {
.howto>h1 {
        font-size: 40px;
        text-align: center;
    }
        .howto__stepOne {
            min-width: 280px;
            height: 320px;
        }
     .howto__stepTwo {
         min-width: 280px;
         }
}
@media screen and (max-width:320px) {
  
    .howto__stepOne {
        min-width: 250px;
        height: 320px;
    }

    .howto__stepTwo {
        min-width: 250px;
       
    }
}