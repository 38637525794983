.contact__us{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
    padding-top: 40px;
}
.contact__us h1{
    font-weight: normal;
    color: white;
    font-size: 60px;
}
.contact__us p{
    color: #D4CECE;
}
.contact__usHeader{
    width: 30%;
    min-width: 300px;
    text-align: center;
}
.contact__name{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.contact__nameOne{
    display: flex;
    flex-direction: column;
    margin: 10px;
}
.contact__name input{
    width: 100%;
    min-width: 290px;
    height: 30px;
    padding-left: 5px;
    color:white;
}
.contact__us input{
   
    background-color: transparent;
    border: 1px solid #008136;
    border-radius: 10px;
    color: white;
    
}
.contact__us label{
    color: white;
    margin-bottom: 10px;
    margin-top: 10px;
}
.contact__email{
    display: flex;
    flex-direction: column;
}
.contact__email input{
    background-color: transparent;
    min-width: 610px;
    width: 100%;
    height: 30px;
    padding-left: 5px;
    color: white;
    
}
.contact__message{
    display: flex;
    flex-direction: column;
}
.contact__message textarea{
    min-width: 610px;
    width: 100%;
    height: 200px;
    background-color: transparent;
    border: 1px solid #008136;
    border-radius: 19px;
    padding-left: 5px;
    padding-top: 20px;
    margin-top: 10px;
    color: white;
}
.contact__us button{
    background: #008136 0% 0% no-repeat padding-box;
    border: 1px solid #008136;
    border-radius: 21px;
    font: normal normal bold 30px/34px Arial;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 30px auto;
    min-width: 630px;

    height: 60px;
}
@media screen and (max-width:628px) {
.contact__email input {
        min-width: 300px;
        width: 100%;
    
    }

    .contact__message textarea {
        min-width: 300px;
        width: 100%;
    
    }
        .contact__us button {
       
            min-width: 300px;
    
       
        }
}
@media screen and (max-width:320px) {
    .contact__message textarea {
            min-width: 250px;
          
        }
                .contact__email input {
                    min-width: 250px;
                 
                }
                                .contact__name input {
                                     min-width: 250px;
                                  
                                    padding-left: 5px;
                                }
}